import { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Field } from "react-final-form"

import PhenoButton from "../atoms/pheno_button"
import FinalFormTextField from "../atoms/final_form_text_field"
import Loading from "../organism/loading"
import WhiteCard from "./white_card"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"

const styles = {
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    height: "76px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "18.75rem",
    width: "20rem",
  },
  title: (theme) => ({
    fontSize: theme.variables.fontSizes.title,
    fontWeight: 700,
  }),
  message: { color: "rgba(0,0,0,.6)", textAlign: "center" },
  fieldContainer: { width: "50%" },
  button: { width: "160px" },
  spanJoin: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}

const AuthForm = (props) => {
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const validate = (values) => {
    const message = props.validate(values.single)
    return message ? { single: message } : {}
  }

  const onSubmit = async (values) => {
    if (props.inAction) {
      return
    }
    props.onSubmit(values.single)
  }

  const renderContent = () => {
    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form style={styles.form}>
            <Box sx={styles.textContainer}>
              <Box sx={styles.title}>{props.title}</Box>
              <Box sx={styles.message}>{props.message}</Box>
            </Box>
            <Box sx={styles.fieldContainer}>
              <Field
                name="single"
                type={props.fieldType}
                component={FinalFormTextField}
                label={props.fieldLabel}
                inputRef={inputRef}
                inputProps={props.fieldInputProps}
                variant="standard"
              />
            </Box>
            <PhenoButton type="submit" variant="contained" color="primary" onClick={handleSubmit} sx={styles.button}>
              {props.buttonText}
            </PhenoButton>
          </form>
        )}
      />
    )
  }

  return (
    <WhiteCard footer={props.footer}>
      <Loading inAction={props.inAction} />
      {renderContent()}
    </WhiteCard>
  )
}

AuthForm.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldInputProps: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  inAction: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default AuthForm
